.nv__contact__form{
    width: 100%;
    height: auto;
    display: flex;
    padding: 40px 0px 40px 0px;
    justify-content: center;
    align-items: center;
   

    &__block{
        width: 40%;
        height: auto;
        padding-left: 10px;

        &--info{
            width: 30%;
        }

        &--complains{
            width: 50%;
        }
    }


    h3{
        font-size: 20px;
        margin-bottom: 40px;
    }

    h4{
        font-size: 16px;
        margin-bottom: 30px;
    }

    p{
        font-size: 14px;
        line-height: 20px;
    }

    .contact__data{
        width: 100%;
        height: auto;
        margin-bottom: 30px;

        &__container{
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
        }
        &__icon{
            width: 30px;
            height: auto;
            font-size: 30px;
            margin-right: 10px;
        }

        &__text{
            width: 80%;
            strong{
                display: block;
                margin-bottom: 10px;
                font-weight: 600;
            }  
        }
    }


    .contact__form__base{
        width: 100%;
        height: auto;
        background-color: $strong-blue-color;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;

        &__container{
            width:90%;
            height: auto;
            margin: 0 auto;
        }
        &__logo{
            width: 200px;
            height: auto;
            margin-bottom: 30px;
        }

        h2{
            font-size: 20px;
            margin-bottom: 30px;
        }

        p{
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 30px;
        }
    }

    .contact__form__fields{
        width: 100%;
        display: block;
        input{
            width: 100%;
            height: 30px;
            background: transparent;
            border: 0px;
            color: $white-color;
            border-bottom: 1px solid $white-color;
            padding-bottom: 10px;
            margin-bottom: 30px;
        }

        ::placeholder {
            color: $white-color;
            opacity: 1; /* Firefox */
        }

        .labelError{
            display: block;
            color: red;
            margin-bottom: 10px;
        }
        input[type='submit'] {
            width: 300px;
            height: 40px;
            margin: 0 auto;
            display: block;
            background-color: transparent;
            border: 1px solid $white-color;
            color: $white-color;
            cursor: pointer;
            font-size: 14px;
            border-radius: 50px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }


    &--service{
        .contact__form__base{
            background-color: $white-color;
        }

        .contact__form__base__logo{
            display: block;
            margin: 0 auto !important;
            margin-bottom: 30px !important;
        }

        .nv__contact__form__block{
            width: 50% !important;
            p{
                text-align: left !important;
            }

            h4{
                font-size: 18px;
                line-height: 28px;
                width: 90%;
                text-align: center;
            }
        }
        input{
            width: 100%;
            height: 30px;
            border: 0px;
            border-bottom: 1px solid #757575 !important;
        }

        ::placeholder {
            color: #757575 !important;
            opacity: 1; /* Firefox */
        }

        input[type='submit'] {
            border: 1px solid $strong-blue-color !important;
            color: $strong-blue-color !important;

            &:hover{
                background-color: $strong-blue-color;
                color: $white-color !important  ;
            }
        }
    }

}

@media screen and (max-width: 992px){
    .nv__contact__form{
        .contact__form__fields{
            width: 100%;
            display: block;
            input[type='submit'] {
                width: 280px;
                height: 40px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .nv__contact__form{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    
        &__block{
            width: 100%;
            height: auto;
            padding-left: 10px;
        }

        

        &--service{
            .nv__contact__form__block{
                width: 100% !important;
            }

            h4{
                font-size: 16px !important;
                line-height: 26px !important;
            }
        }
        
    }
}

@media screen and (max-width: 568px){
    .nv__contact__form{
        .contact__form__fields{
            input[type='submit'] {
                width: 100%;
                height: 40px;
            }
        }
    }
}