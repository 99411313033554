.nv__values{
    width: 100%;
    height: auto;
    margin-bottom: 0px ;
    
    .nv__over{
        &:hover{
            .nv__values__info{
                opacity: 1;
                z-index: 1;
                animation:scale-up-center 0.4s; 
            }
        }
    }
    .nv__section__container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__selector{
        width: 300px;
        height: 50px;
        background-color: #BDE8FF;
        margin: 0 auto;
        margin-bottom: 30px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__options{
            width: 50%;
            height: 45px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $strong-blue-color;
            margin-right: 5px;
            margin-left: 5px;
            cursor: pointer;

            &--active{
                background-color: $strong-blue-color;
                color: $white-color;
            }
        }
    }

    &__item__content{
        width: 33.33%;
        height:auto;
    }
    &__item{
        width: 100%;
        height:auto;
        position: relative;
        background-size: cover;
        background-position: center center;
    }

    &__container{
        width: 100%;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        h3{
            color: $white-color;
            font-size: 22px;
        }
    }

    &__info{
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-color;
        text-align: center;
        opacity: 0;
        z-index: -1;

        p{
            width: 90%;
            height: auto;
            margin: 0 auto;
        }

    }

    @keyframes scale-up-center{ 0%{transform:scale(.5)} 100%{transform:scale(1)} }
}



@media screen and (max-width: 1170px){
    .nv__values{
        width: 100%;
        
        .nv__section__container{
            width: 95%;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__values{
        width: 100%;
        
        &__container{
            height: 200px;
            h3{
                font-size: 18px;
            }
        }
        &__info{
            p{
                width: 80%;
                height: auto;
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .nv__values{
        width: 100%;
        
        &__container{
            height: 180px;
            h3{
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 680px){
    .nv__values{
        width: 100%;
        
        &__item__content{
            width: 50%;
        }
        
        &__container{
            height: 180px;
            h3{
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 480px){
    .nv__values{
        &__container{
            height: 200px;
            h3{
                font-size: 16px;
            }
        }

        &__info{
            p{
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 380px){
    .nv__values{
        &__container{
            height: 160px;
            h3{
                font-size: 16px;
            }
        }

        &__info{
            p{
                font-size: 12px;
            }
        }
    }
}