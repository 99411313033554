.nv__blocks__info{
    width: 100%;
    height:auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;

    &--top{
        padding-top: 120px;
    }

   
    &__item{
        width: 33.33%;
        height: auto;
        margin-bottom: 30px;

        &__container{
            width: 90%;
            height: 300px;
            background-color: #FFF;
            margin: 0 auto;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            position: relative;

            &:hover{
                .logo{
                    animation:scale-up-center 0.4s;
                }
            }

            .logo{
                width: 100%;
                height: auto;
                margin: 0 auto;
                padding-top: 50px;
                margin-bottom: 30px;
            }

            .info{
                width: 100%;
                height: auto;
                text-align: center;
                position: absolute;
                bottom: 30px;
                h4{
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 10px !important;
                    color: #000;
                    font-weight: bold;
                }

                a{
                    font-size: 12px;
                    text-transform: uppercase;
                    text-decoration: underline;
                }

            }
        }
    }
}

.nv__alliances{
    background: url(../../assets/images/alliances/portrait-min.jpg) no-repeat center center/cover;
}
.nv__alliances p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 40px;
}



@keyframes scale-up-center{ 0%{transform:scale(.5)} 100%{transform:scale(1)}}

@media screen and (max-width: 1170px){
    .nv__blocks__info{
        .nv__section__container{
            width: 95%;
        }
    }
}


@media screen and (max-width: 992px){
    .nv__blocks__info{
        margin-bottom: 0px;
        &__item{
            &__container{
                width: 90%;
                height: 240px;

                .info{

                    h4{
                        width: 90%;
                        margin: 0 auto;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 680px){
    .nv__blocks__info{
        &__item{
            width: 50%;
            &__container{
                width: 90%;
                height: 240px;

                .info{

                    h4{
                        width: 90%;
                        margin: 0 auto;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px){
    .nv__blocks__info{
        &__item{
            width: 50%;
            &__container{
                height: 180px;
            }
        }

        h4{
            display: none;
        }
    }
}

@media screen and (max-width: 345px){
    .nv__blocks__info{
        &__item{
            &__container{
                height: 160px;
            }
        }
    }
}