.affiliates__page{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-color;

    &--ptg{
        background: url('../../../assets/images/affiliates/ptg/portrait.jpg') no-repeat center center/cover;
    }

    &--panamerican{
        background: url('../../../assets/images/affiliates/panamerican/portrait.png') no-repeat center center/cover;
    }

    &--metro{
        background: url('../../../assets/images/affiliates/metro/portrait.png') no-repeat center center/cover;
    }

    &--donna{
        background: url('../../../assets/images/affiliates/donnaStella/portrait.png') no-repeat center center/cover;
    }

    &--harbor{
        background: url('../../../assets/images/affiliates/harbor/portrait.png') no-repeat center center/cover;
    }

    &--reffsolution{
        background: url('../../../assets/images/affiliates/reffsolution/portrait.png') no-repeat center center/cover;
    }

    &--ptyss{
        background: url('../../../assets/images/affiliates/ptyss/portrait.png') no-repeat center center/cover;
    }

    &--beermark{
        background: url('../../../assets/images/affiliates/beermark/portrait.png') no-repeat center center/cover;
    }

    h1{
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 30px;
    }

    p{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 30px;
        text-align: justify;
    }
    &__section{
        max-width: 1170px;
        height: auto;
        margin: 0 auto;
    }

    &__footer{
        max-width: 1170px;
        height: auto;
        position: absolute;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        bottom: 30px;
        display: flex;
        justify-content: right;

        .btn__action{
            padding: 0px 20px;
            height: 40px;
            color: $white-color;
            border: none;
            border-radius: 5px;
            font-size: 18px;
            line-height: 28px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                background: $blue-color;
                color: $white-color;
            }
        }
    }
}


@media screen and (max-width: 1170px){
    .affiliates__page{
        &__section{
            width: 80%;
        }
    }
}


@media screen and (max-width: 992px){
    .affiliates__page{
        h1{
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 30px;
        }
    
        p{
            font-size: 18px;
            line-height: 28px;
        }
    }
}


@media screen and (max-width: 580px){
    .affiliates__page{
        h1{
            font-size: 26px;
            line-height: 36px;
        }
    
        p{
            font-size: 16px;
            line-height: 26px;
        }

        &__footer{
            .btn__action{
                position: relative;
                top: -100px;
            }
        }
    }
}
