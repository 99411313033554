.nv__about__us__slide{
    width: 100%;
    height: 800px;
    margin: 0 auto;
    background-size: cover !important;
    background-position: top center !important;
    background: url('../../../assets/images/about-us/about-us-image-1.png');
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white-color;
    h1{
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 30px;
    }

    p{
        width: 600px;
        height: auto;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        margin-bottom: 20px;
    }

}


@media screen and (max-width: 1170px){
    .nv__about__us__slide{
        width: 100%;
        
        .nv__section__container{
            width: 95%;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__about__us__slide{
        height: 600px;

        .nv__section__container{
            width: 80%;
        }

        h1{
            font-size: 30px;
            line-height: 40px;
            text-align: center;
        }
    
        p{
        width: 100%;
        height: auto;
        margin: 0 auto;
        }
    }
}

@media screen and (max-width: 680px){
    .nv__about__us__slide{
        height: 400px;
        h1{
            font-size: 26px;
            line-height: 36px;
        }
    
        p{
            font-size: 14px;
            line-height: 22px
        }
    }
}