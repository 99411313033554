.nv__blog {
    width: 100%;
    height: auto;
    padding-top: 120px;

    .nv__button {
        display: block;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    &__container {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        align-items: stretch;
    }

    &__item {
        width: 33.32%;
        height: auto;
        margin-bottom: 30px;

        &__container {
            width: 90%;
            height: auto;
            margin: 0 auto;
        }

        &__image {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
        }

        &__data {
            width: 100%;
            height: auto;
            margin: 0 auto;
        }

        span {
            font-size: 14px;
            color: #000;
            display: block;
        }

        h4 {
            width: 100%;
            height: auto;
            margin-bottom: 14px;
            font-size: 18px;
            line-height: 26px;
            font-weight: 800;
        }

        p {
            font-size: 14px;
            color: #000;
            line-height: 20px;
            text-align: justify;
            margin-bottom: 0px;
        }
    }
}

@media screen and (max-width: 1170px) {
    .nv__blog {
        .nv__section__container {
            width: 95%;
        }
    }
}

@media screen and (max-width: 992px) {
    .nv__blog {
        .nv__section__container {
            width: 95%;
        }

        &__item {
            &__data {
                padding-bottom: 10px;
            }
            h4 {
                font-size: 14px;
            }

            p {
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .nv__blog {
        .nv__blog__container {
            flex-wrap: wrap;
        }
        &__item {
            width: 48%;
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 480px) {
    .nv__blog {
        .nv__section__container {
            width: 80%;
        }

        &__item {
            width: 100%;
            margin-bottom: 30px;
        }
    }
}
