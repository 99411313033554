.nv__video{
    width: 50%;
    height: auto;
    margin: 0 auto;

    &__full{
        width: 100%;
    }
    video{
        width: 100%;
        margin: 0 auto
    };
}