.nv__imageInfo {
    width: 100%;
    height: auto;

    &__responsability {
        display: none;
    }
    &--padding {
        padding: 80px 0px 80px 0px;
        margin: 0 auto;
    }

    &__picture {
        width: 50%;
        height: 700px;
        &__image {
            width: 100%;
            height: 100%;
            background-size: cover !important;
            background-position: center center !important;
        }
    }

    &__sign {
        width: 300px;
        height: auto;
        margin: 0 auto;
    }

    &__timon {
        width: 100%;
        height: auto;

        &--web {
            width: 100%;
            height: 600px;
            background: url('../../assets/images//about-us/timon.jpg');
            background-position: bottom center;
            background-size: cover;
            display: block;
        }

        &--mobile {
            display: none;
        }
    }

    &__future {
        width: 100%;
        height: auto;

        &--web {
            width: 100%;
            height: 600px;
            background: url('../../assets/images//about-us/future.jpg');
            background-position: bottom center;
            background-size: cover;
            display: block;
        }

        &--mobile {
            display: none;
        }
    }

    .nv__section__container {
        display: flex;
        justify-content: center;
    }
    &__block {
        width: 50%;
        height: auto;

        &--text {
            width: 50%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .nv__imageInfo__info {
            width: 70%;
            height: auto;

            &--85 {
                width: 85%;
                height: auto;
            }

            &--90 {
                width: 90%;
                height: auto;
            }

            h4 {
                font-size: 18px;
                line-height: 100%;
                margin-bottom: 20px;
            }

            h1 {
                margin-bottom: 20px;
            }
            h2 {
                font-size: 30px;
                line-height: 36px;
                margin-bottom: 20px;
            }

            .mb-10 {
                margin-bottom: 10px !important;
            }
            span {
                display: block;
                margin-bottom: 20px;
            }
            p {
                text-align: justify;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 20px;
            }

            ul {
                text-align: justify;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 30px;
                li {
                    margin-bottom: 20px;
                }
            }
        }

        &--image {
            width: 50%;
            height: 700px;
            background-size: cover !important;
            background-position: center center !important;
        }

        &--image--square {
            width: 50%;
            height: 100%;
            background-size: cover !important;
            background-position: center center !important;
        }

        &--image--small {
            width: 35%;
            height: 100%;
            background-size: cover !important;
            background-position: center center !important;
        }
    }
}

@media screen and (max-width: 992px) {
    .nv__imageInfo {
        padding: 60px 0px 60px 0px;
        .nv__section__container {
            display: block;
        }

        &__picture {
            width: 100%;
            height: 400px;
            margin: 0 auto;
        }

        &__responsability {
            display: block;
        }

        &__timon {
            width: 100%;
            height: auto;

            &--web {
                display: none;
            }

            &--mobile {
                display: block;
                margin-bottom: 20px;
            }
        }

        &__future {
            width: 100%;
            height: auto;

            &--web {
                display: none;
            }

            &--mobile {
                display: block;
                margin-bottom: 20px;
            }
        }

        .nv__imageInfo__info {
            width: 100%;

            h2 {
                font-size: 26px;
                line-height: 32px;
            }
        }
        &__block {
            width: 100%;
            height: auto;
            &--text {
                width: 100%;
            }

            &--image {
                width: 100%;
                height: auto;
                margin: 0 auto;
            }

            &--image--square {
                width: 100%;
                margin: 0 auto;
            }

            &--image--small {
                width: 35%;
                margin: 0 auto;
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 680px) {
    .nv__imageInfo {
        &__block {
            &--image {
                width: 80%;
                margin: 0 auto;
                margin-bottom: 20px;
            }

            &--full {
                width: 100%;
                margin: 0 auto;
                margin-bottom: 20px;
            }

            &--image--small {
                width: 80%;
            }
        }

        &__sign {
            width: 100%;
            height: auto;
        }

        .nv__imageInfo__info {
            h2 {
                font-size: 22px;
                line-height: 28px;
            }

            h4 {
                font-size: 16px;
            }

            p {
                font-size: 14px;
                line-height: 22px;
            }
        }

        &__picture {
            width: 100%;
            height: 300px;
        }
    }
}

@media screen and (max-width: 380px) {
    .nv__imageInfo {
        &__picture {
            width: 100%;
            height: 260px;
        }
    }
}
