.main__page{
    width: 1380px;
    height: auto;
    margin: 0 auto;
    min-width: 320px;

    &--full{
        width: 100%;
        height: auto;
    }
}

.nv__section{
    width: 100%;
    height: auto;
    margin-bottom: 60px;

    &--no-border{
        margin-bottom: 0px !important;
    }

    &--with-padding{
        padding: 60px 0px 60px 0px;
    }

    &__container{
        width: 1170px;
        height: auto;
        margin: 0 auto;
    }

    &__title{
        font-size: 40px;
        line-height: 50px;
        font-weight: 600;
        margin-bottom: 60px;
    }
}


@media screen and (max-width: 1170px){

    .nv__section{
        &__container{
            width: 80%;
        }
    }
}


@media screen and (max-width: 992px){

    .nv__section{
        margin-bottom: 40px;
        &__title{
            font-size: 30px;
            line-height: 30px;
            margin-bottom: 40px;
        }
    }
}


@media screen and (max-width: 480px){

    .nv__section{
        margin-bottom: 40px;
        &__title{
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 40px;
        }
    }
}