.nv__esg__page {
    width: 100%;
    height: auto;
    background: url('../../../assets/images/esg/esg-dots-bg.png');
    background-size: cover;
    overflow: hidden;

    &__portrait {
        width: 100%;
        height: 680px;
        position: relative;
    }

    &__container {
        max-width: 1320px;
        height: auto;
        margin: 0 auto;
        display: flex;
        align-content: space-between;
        position: relative;
        flex-wrap: wrap;
        top: -220px;
    }

    &__block {
        width: 33.33%;
        height: auto;
        margin-bottom: 120px;

        &__content {
            width: 90%;
            height: 300px;
            margin: 0 auto;
            border-radius: 20px;
            box-shadow:
                rgba(0, 0, 0, 0.19) 0px 10px 20px,
                rgba(0, 0, 0, 0.23) 0px 6px 6px;
        }
    }

    &__block__slider {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        display: none;

        &__image {
            width: 100%;
            height: 220px;
        }
    }

    &__section {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__info {
        width: 100%;
        height: auto;
        border-radius: 0px;
        position: relative;
        color: $black-color;

        h1 {
            font-size: 30px;
            line-height: 40px;
            font-weight: 700;
            color: $black-color;
            font-family: 'Montserrat-Bold';
            margin-bottom: 20px;
        }

        p {
            width: 100%;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: $black-color;
            font-family: 'Montserrat-Regular';
            margin-bottom: 20px;
        }

        video {
            width: 200px;
            display: block;
            margin: 0 auto;
        }
    }

    .item__esg__1 {
        background: url('../../../assets/images/esg/ESG-1-1.webp');
        background-size: cover;
        display: block;
    }

    .item__esg__2 {
        background: url('../../../assets/images/esg/ESG-3-3.webp');
        background-size: cover;
        background-position: center;
        display: block;
    }

    .item__esg__3 {
        background: url('../../../assets/images/esg/ESG-2.jpg');
        background-size: cover;
        display: block;
    }
}

.home__animate {
    width: 100%;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
        font-size: 50px;
        font-weight: 700;
        color: #fff;
        font-family: 'Montserrat-Bold';
    }
}

@media screen and (max-width: 1360px) {
    .nv__esg__page {
        &__container {
            max-width: 90%;
        }
    }
}

@media screen and (max-width: 1170px) {
    .nv__esg__page {
        .home__animate {
            width: 100%;
            height: 480px;
        }
        &__portrait {
            width: 100%;
            height: 600px;
            position: relative;
        }

        &__block {
            margin-bottom: 80px;

            &__content {
                width: 90%;
                height: 240px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .nv__esg__page {
        &__container {
            max-width: 80%;
        }
        .home__animate {
            width: 100%;
            height: 480px;
        }
        &__portrait {
            width: 100%;
            height: 600px;
            position: relative;
        }

        &__block {
            margin-bottom: 80px;

            &__content {
                width: 90%;
                height: 200px;
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .nv__esg__page {
        .home__animate {
            width: 100%;
            height: 320px;
            margin-bottom: 40px;
        }

        &__container {
            max-width: 80%;
            top: 0px;
        }
        &__portrait {
            width: 100%;
            height: auto;
            position: relative;
        }

        &__block {
            margin-bottom: 30px;

            &__content {
                width: 90%;
                height: 200px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .nv__esg__page {
        .home__animate {
            width: 100%;
            height: 280px;
        }

        &__block {
            &__content {
                width: 90%;
                height: 160px;
            }
        }

        &__section {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }

        &__info {
            h1 {
                font-size: 24px;
                line-height: 30px;
            }

            p {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .plant-animation {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 580px) {
    .nv__esg__page {
        &__block {
            display: none;
        }

        &__block__slider {
            display: block;

            &__image {
                width: 100%;
                height: 220px;
            }
        }

        &__info {
            width: 100%;
        }

        .plant-animation {
            transform: scale(0.8);
        }
    }
}
