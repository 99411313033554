.nv__service__page{
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;

    h1{
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 30px;   
    }

    p{
        font-size: 18px;
        line-height: 28px;
        text-align: center;
    }

    .nv__overlay{
        display: flex;
        justify-content: center;
        align-items: center;
    }


    &__banner{
        width: 100%;
        height: 800px;
        display: flex;
        align-items: center;
        position: relative;
        background-size: cover !important;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        color: $white-color;
        &--agro{
            background-position:  center !important;
        }

        &--small{
            height: 400px;
        }
        &__logo{
            width: 280px;
            height: auto;
            margin-bottom: 20px;
        }
    
    }


    &__info{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 80px 0px 80px 0px;

        &__line{
            width: 600px;
            height: 4px;
            background-color: $black-color;
            margin: 0 auto 30px auto;
        }

        &__container{
            width: 100%;
            height: auto;
        }

        h1{
            text-align: center;
        }
    }

    &__char{
        width: 100%;
        height: 800px;
        display: flex;
        align-items: center;
        background-size: cover !important;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;


        position: relative;
        color: $white-color;
        text-align: center;

        p{
            width: 80%;
            margin: 0 auto;
            margin-bottom: 50px;
        }
        .nv__overlay{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .services__blocks{
        width: 800px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: 0 auto;
        margin-bottom: 40px;
        

        &__item{
            width: 33.33%;
            height: auto;
        }

        &__image{
            width: 80px;
            margin: 0 auto;
        }
    }


    &__contact{
        width: 100%;
        height: auto;
        padding: 80px 0px 80px 0px;
    }
}

    @media screen and (max-width: 1170px){
        .nv__service__page{
            width: 100%;
            
            .nv__section__container{
                width: 80%;
            }
        }
    }

    @media screen and (max-width: 992px){
        .nv__service__page{

            h1{
                font-size: 30px;
                line-height: 40px;
                text-align: center;
                margin-bottom: 26px;
            }

            p{
                font-size: 16px;
                line-height: 26px;
            }

            &__banner{
                width: 100%;
                height: 400px;   

                &__logo{
                    width:260px;
                    margin: 0 auto;
                    margin-bottom: 10px;
                }

                
                .nv__section__container{
                    width: 300px;
                }
            }

            

            .services__blocks{
                width:100%;
            }

            &__info{
        
                &__line{
                    width: 100%;
                }
            }

            &__contact{
                padding: 40px 0px 40px 0px;
            }
        }
        
    }

    @media screen and (max-width: 768px){       
            .nv__service__page{
    
                h1{
                    font-size: 26px;
                    line-height: 36px;
                }

                &__banner{
                    background-attachment: unset;
                }

                &__char{
                    background-attachment: unset;
                }
            }
        
    }



    @media screen and (max-width:480px){

        .nv__service__page{

            &__char{
                height: 1000px;
            }
            .services__blocks{
                flex-wrap: wrap;

                &__items{
                    width:100%;
                }

                &__image{
                    width: 60px;
                    margin-bottom: 10px;
                }
            }

            &__contact{
                padding: 20px 0px 20px 0px;
            }
        }
    
}
