$start: 0.5;
$increment: 0.5;
$count: 10;

@for $i from 1 through $count {
    $delay: $start + ($i - 1) * $increment;

    .animate__animated {
        &.animate__delay-#{$i}s {
            -webkit-animation-delay: calc(1s * #{$delay}) !important;
            animation-delay: calc(1s * #{$delay}) !important;
            -webkit-animation-delay: calc(var(--animate-delay) * #{$delay}) !important;
            animation-delay: calc(var(--animate-delay) * #{$delay}) !important;
        }
    }
}
