.nv__single{
    &__header{
        width: 100%;
        height: 100px;
        background-color: $black-color;
        margin-bottom: 40px;
    }

    &__container{
        width: 80%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    &__thumbnail{
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-bottom: 0px;
    }

    &__title{
        width: 100%;
        height: auto;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
    }




    p{
        width: 100%;
        height: auto;
        text-align: justify;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 26px;
    }

    img{
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }
    iframe{
        width: 100%;
        height: 500px;
        margin-bottom: 30px;
    }

    &__nextprev{    
        width: 100%;
        height: auto;
        margin-top: 40px;
        display: block;
        
        .item__next{
            float: right;
        }

        .item__prev{
            float: left;
        }
        span{
            display: inline-block;
            height: auto;
            background-color: $blue-color;
            border-radius: 10px;
            padding: 10px;
            color: $white-color;
            margin-bottom: 10px;
            &:hover{
                background-color: $strong-blue-color;
                scale: 1.1;
            }
            svg{
                position: relative;
                top: 3px;
            }
        }

        h4{
            width: 100%;
            height: auto;
            font-size: 12px;
            line-height: 12px;
            padding-bottom: 10px;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 768px){
    .nv__single{

        &__thumbnail{
            margin-bottom: 20px;
        }


        &__container{
            width: 100%;
        }

        &__title{
            font-size: 20px;
            line-height: 30px;
        }

        p{
            font-size: 14px;
            line-height: 24px;
        }

        iframe{
            width: 100%;
            height: 300px;
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: 480px){
    .nv__single{


        iframe{
            width: 100%;
            height: 220px;
            margin-bottom: 20px;
        }
    }
}