.nv__services{
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;

    &--full{
        padding-top: 180px;
    }

    
    &__container{
        width: 100%;
        height: auto;
        display: flex;
    }

    .subtitle{
        text-transform: uppercase;
    }

    &__seemore{
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;

        svg{
            font-size: 12px;
            margin-left:5px;
        }
    }

    &__agro{
        width:100%;
        height: auto;
        position: relative;

        .nv__services__block__item{
            width: 100%;
            height: 260px;
            background: url("../../../assets/images/home/services/service-4.jpg");
            background-size: cover;
            background-position:  center;
            
        }

        &__logo{
            width: 140px;
            height: auto;
            position: absolute;
            right: 20px;
            bottom: 20px;
        }

        p{
            width: 50%;
        }

      
    }

    &__info{
        width: 80%;
        height: auto;
        padding:40px 0px 0px 0px;
        color: $white-color;
        span{
            font-size: 11px;
            line-height: 20px;
        }
        h3{
            font-size:  30px;
            line-height: 36px;
            margin-bottom: 20px;
        }

        p{
            text-align: justify;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
        }
        a{
            text-transform: none !important;
            font-size: 14px;
            color: $white-color;
        }
    }

    &__block{
        width: 50%;
        height: 100%;
        margin-bottom:10px;

        &__item{
            width: 98%;
            height: 610px;
            background: url("../../../assets/images/home/services/service-1-new.png");
            background-size: cover;
            background-position: left center;
            display: flex;
            align-items: center;
            justify-content: center;
            &--50-1{
                width: 100%;
                height: 300px;
                background: url("../../../assets/images/home/services/service-2-new.png") ;
                background-size: cover;
                margin-bottom: 10px;
            }
            &--50-2{
                width: 100%;
                height: 300px;
                background: url("../../../assets/images/home/services/service-6-new.png");              
                background-position: top;
                background-size: cover;
            }
        }
    }


}



@media screen and (max-width: 1170px){
    .nv__services{
        width: 100%;
        
        .nv__section__container{
            width: 95%;
        }
    }
}


@media screen and (max-width: 992px){
    .nv__services{
        margin-bottom: 40px;
        &__info{
            h3{
                font-size:  26px;
                line-height: 32px;
            }
    
            p{
                text-align: justify;
                font-size: 14px;
                line-height: 18px;
            }
            
        }
    }
}


@media screen and (max-width: 768px){
    .nv__services{
    
        &__block{
            margin: 0px;
            &__item{
                width: 98%;
                height: 410px;
                &--50-1{
                    width: 100%;
                    height: 200px;
                }
                &--50-2{
                    width: 100%;
                    height: 200px;
                }
            }
        }

        &__agro{
            .nv__services__block__item{
                width: 100%;
                height: 200px;
            }

            &__logo{
                display: none;
            }
            p{
                width: 100%;
            }
        }
    }

}

@media screen and (max-width: 680px){
    .nv__services{
        .nv__section__container{
            width: 100%;
        }
        &__info{
            width: 90%;
            padding: 20px 0px 0px 20px;
            h3{
                font-size:  20px;
                line-height: 26px;
            }
    
            p{
                text-align: justify;
                font-size: 14px;
                line-height: 18px;
            }
        }

        &__container{
            flex-wrap: wrap;
        }
        &__block{
            width: 100%;
            
            &__item{
                width: 100%;
                height: 200px;
                margin-bottom: 0px;
                &--50-1{
                    width: 100%;
                    height: 200px;
                }
                &--50-2{
                    width: 100%;
                    height: 200px;
                }
            }
        }

        
    }
}